import clsx from 'clsx'
import Image from 'next/image'
import { type FunctionComponent, useState } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { formatDate } from '../utilities/formatDate'
import { roundTheCurrentTimeToMinutes } from '../utilities/roundTheCurrentTimeToMinutes'
import { Button } from './Button'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import styles from './WebinarPage.module.sass'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type WebinarPageProps = NonNullable<PageProps['page']['webinar']>

export const WebinarPage: FunctionComponent<WebinarPageProps> = ({
	title,
	dates,
	coverImage,
	content,
	labels,
	place,
}) => {
	const [selectedDate, setSelectedDate] = useState(dates[0])

	const translation = useTranslate()
	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<div className={styles.info}>
					<h1 className={styles.title}>{title}</h1>
					{dates && dates.length > 1
						? dates.map(
								(date) =>
									date.date && (
										// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
										<div key={date.id} className={styles.dateAndPlace} onClick={() => setSelectedDate(date)}>
											{selectedDate.id === date.id && <div className={styles.selected} />}
											<div className={clsx(selectedDate.id === date.id && styles.dateSelected, styles.date)}>
												{' '}
												{formatDate('cs', date.date, true)}
											</div>
										</div>
									),
						  )
						: dates[0] && (
								<div className={clsx(styles.dateAndPlace, styles.dateAndPlaceOnly)}>
									{dates[0].date && formatDate('cs', dates[0].date, true)}
								</div>
						  )}
					{place && <div className={styles.place}>{place}</div>}
					{labels && labels.length > 0 && (
						<div className={styles.labelGroup}>
							{labels.map((label) => (
								<span className={styles.label} key={label.id}>
									{label.title}
								</span>
							))}
						</div>
					)}
					{selectedDate?.registrationLink &&
						selectedDate?.isOpenForRegistration &&
						selectedDate?.date &&
						selectedDate?.date > roundTheCurrentTimeToMinutes() && (
							<div className={styles.buttonWrapper}>
								<Button type="link" href={selectedDate.registrationLink}>
									{translation('webinarPage.register')}
								</Button>
							</div>
						)}
				</div>
				<div className={styles.content}>
					{coverImage?.url && (
						<div className={styles.imageWrapper}>
							<Image
								className={styles.image}
								src={coverImage?.url}
								fill
								sizes="(min-width: 48rem) 50vw, 100vw)"
								alt={coverImage?.alt ?? ''}
								quality={75}
								priority
							/>
						</div>
					)}
					{content && (
						<div className={styles.contentWrapper}>
							<ContentRenderer content={content} containerDisableGutters disableBottomSpacing />
						</div>
					)}
				</div>
			</div>
		</Container>
	)
}
