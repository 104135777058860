import Spline from '@splinetool/react-spline'
import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { useEffect, useRef, useState } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { api } from '../utilities/api'
import { formatDate } from '../utilities/formatDate'
import { excludeContentLockBlocks } from '../utilities/getNonHiddenContentBlocks'
import { getReadingTime } from '../utilities/getReadingTime'
import { ArticleBanner } from './ArticleBanner'
import styles from './ArticlePage.module.sass'
import { ArticleShareTrack } from './ArticleShareTrack'
import { Button } from './Button'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import { Embed } from './Embed'
import { RelatedArticle } from './RelatedArticle'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type ArticlePageProps = NonNullable<PageProps['page']['article']>

export enum ArticleType {
	magazine = 'magazine',
	podcast = 'podcast',
	productNews = 'productNews',
	tv = 'tv',
	wiki = 'wiki',
}

export const ArticlePage: FunctionComponent<ArticlePageProps> = ({
	title,
	coverPhoto,
	content,
	readingTime,
	link,
	actualizedAt,
	publishedAt,
	relatedArticles,
	podcastCategories,
	textToSpeechFile,
	productNewsCategories,
	tvCategories,
	wikiCategories,
	articlesPageLink,
	podcastPageLink,
	productNewsPageLink,
	tvPageLink,
	lead,
	coverIframe,
	coverEmbed,
	wikiPageLink,
	author,
}) => {
	const translation = useTranslate()

	const newestRelatedArticles = relatedArticles.slice(0, 2)
	const restRelatedArticles = relatedArticles.slice(2)

	const [isLockUnlocked, setIsLockUnlocked] = useState(false)
	const [displayedContent, setDisplayedContent] = useState(content)
	const type = productNewsCategories[0]
		? ArticleType.productNews
		: tvCategories[0]
		? ArticleType.tv
		: wikiCategories[0]
		? ArticleType.wiki
		: podcastCategories[0]
		? ArticleType.podcast
		: ArticleType.magazine

	const getLink = (type: ArticleType) => {
		switch (type) {
			case ArticleType.productNews:
				return {
					link: productNewsPageLink,
					title: translation('articlePage.relatedArticles.productNews.linkTitle'),
				}
			case ArticleType.magazine:
				return {
					link: articlesPageLink,
					title: translation('articlePage.relatedArticles.magazine.linkTitle'),
				}
			case ArticleType.podcast:
				return {
					link: podcastPageLink,
					title: translation('articlePage.relatedArticles.podcast.linkTitle'),
				}
			case ArticleType.tv:
				return {
					link: tvPageLink,
					title: translation('articlePage.relatedArticles.tv.linkTitle'),
				}
			case ArticleType.wiki:
				return {
					link: wikiPageLink,
					title: translation('articlePage.relatedArticles.wiki.linkTitle'),
				}

			default:
				return {
					link: articlesPageLink,
					title: translation('articlePage.relatedArticles.articles.linkTitle'),
				}
		}
	}

	const fullContent = api.getArticleContent.useQuery({
		articleContentId: content.id,
	})

	const resultContent = isLockUnlocked ? excludeContentLockBlocks(fullContent.data?.getContent) : content

	useEffect(() => {
		if (isLockUnlocked) {
			if (fullContent.data?.getContent) {
				setDisplayedContent(fullContent.data.getContent)
				console.log('visible', displayedContent)
			}
		}
	}, [displayedContent, fullContent.data?.getContent, isLockUnlocked])

	const componentRef = useRef<HTMLDivElement | null>(null)

	const shareTrack = (
		<div className={styles.shareTrack}>
			<ArticleShareTrack articleUrl={link?.url} title={title} componentRef={componentRef} />
		</div>
	)
	return (
		<div className={styles.printWrapper} ref={componentRef}>
			<div className={styles.wrapper}>
				{coverEmbed && (
					<Container size="normal">
						<Embed {...coverEmbed} />
					</Container>
				)}
				<Container size="small">
					<div className={styles.info}>
						{!coverEmbed && title && <h1 className={styles.title}>{title}</h1>}
						<div className={clsx(styles.meta, coverEmbed && styles.metaEmbed)}>
							{actualizedAt && publishedAt ? (
								<div className={styles.actualized}>
									<div className={clsx(styles.metaItem, coverEmbed && styles.noLeftMargin)}>
										{formatDate('cs', actualizedAt)} {translation('article.actualizedAt')}
									</div>
									<div className={clsx(styles.metaItem, coverEmbed && styles.noLeftMargin)}>
										{formatDate('cs', publishedAt)}
									</div>
								</div>
							) : (
								publishedAt && (
									<span className={clsx(styles.metaItem, coverEmbed && styles.noLeftMargin)}>
										{formatDate('cs', publishedAt)}
									</span>
								)
							)}

							{author?.name && <span className={styles.metaItem}>{author.name}</span>}
							{readingTime && readingTime > 0 ? (
								<span className={styles.metaItem}>
									{readingTime} {translation(`${getReadingTime(readingTime)}`)}
								</span>
							) : undefined}
						</div>

						{coverEmbed && title && <h1 className={styles.title}>{title}</h1>}
						{lead && <div className={styles.lead}>{lead}</div>}
						{coverIframe?.includes('spline') ? (
							<>
								<div className={styles.spline}>
									<Spline scene={coverIframe} />
								</div>
								{coverPhoto && !coverEmbed && (
									<div className={styles.splineImage}>
										<Image
											className={styles.image}
											src={coverPhoto.url}
											fill
											sizes="(min-width: 48rem) 75vw, 100vw)"
											alt={coverPhoto.alt ?? ''}
											priority
											quality={75}
										/>
									</div>
								)}
							</>
						) : (
							<>
								{coverPhoto && !coverEmbed && (
									<div className={styles.imageWrapper}>
										<Image
											className={styles.image}
											src={coverPhoto.url}
											fill
											sizes="(min-width: 48rem) 75vw, 100vw)"
											alt={coverPhoto.alt ?? ''}
											priority
											quality={75}
										/>
									</div>
								)}
							</>
						)}
					</div>
				</Container>
				<div className={styles.content}>
					<Container size="small">
						{textToSpeechFile && (
							<div className={styles.textToSpeechSection}>
								<div className={styles.textToSpeech}>
									<div className={styles.audio}>
										<h4>{translation('article.audio.title')}</h4>
										{/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
										<audio src={textToSpeechFile.url} title="Text to speech" controls />
									</div>
								</div>
							</div>
						)}
						<div className={styles.contentIn}>
							{resultContent && (
								<ContentRenderer
									content={resultContent}
									containerDisableGutters
									disableBottomSpacing
									lockDeactivated={isLockUnlocked}
									deactivateLock={() => setIsLockUnlocked(true)}
								/>
							)}
							<aside className={styles.relatedWrapper}>
								<div
									className={clsx(
										styles.relatedArticlesWrapper,
										newestRelatedArticles &&
											newestRelatedArticles.length === 0 &&
											styles.relatedArticlesWrapperShareDown,
									)}
								>
									{newestRelatedArticles && newestRelatedArticles.length > 0 ? (
										<>
											<h2 className={styles.relatedTitle}>{translation('articlePage.relatedArticles.title')}</h2>
											<div className={styles.in}>
												<div>
													{newestRelatedArticles.map((article) => (
														<RelatedArticle article={article} key={article.id} />
													))}
												</div>
												{shareTrack}
											</div>
										</>
									) : (
										shareTrack
									)}
								</div>
							</aside>
						</div>
					</Container>
				</div>
				<Container size="small">
					{restRelatedArticles && restRelatedArticles.length > 0 && (
						<div className={styles.restArticlesWrapper}>
							<h2 className={styles.relatedTitle}>{translation('articlePage.relatedArticles.title')}</h2>
							{restRelatedArticles.map((article) => (
								<ArticleBanner article={article} key={article.id} />
							))}
						</div>
					)}

					{articlesPageLink?.url && (
						<div className={styles.buttonWrapper}>
							<Button type="link" variant="secondary" href={getLink(type).link?.url ?? articlesPageLink.url}>
								{getLink(type).title}
							</Button>
						</div>
					)}
				</Container>
			</div>
		</div>
	)
}
